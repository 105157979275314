import { Document, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { format } from "date-fns-tz";
import { useEffect, useState } from "react";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 35,
    paddingHorizontal: 35,
    margin: 0,
  },
  container: {
    flexDirection: "row",
    marginBottom: 3,
  },
  column: {
    flexGrow: 1,
    marginRight: 10,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },

  fieldName: {
    color: "grey",
    fontSize: 8,
  },
  fieldValue: {
    fontSize: 10,
    color: "black",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableCell: {
    width: "auto",
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    padding: 3,
    fontSize: "8px",
    flexGrow: 1,
  },
  tableCell_No: {
    width: "5%",
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    padding: 3,
    fontSize: "8px",
    flexGrow: 1,
  },
  tableCell_Ts: {
    width: "8%",
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    padding: 3,
    fontSize: "8px",
    flexGrow: 1,
  },
  tableCell_Or: {
    width: "10%",
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    padding: 3,
    fontSize: "8px",
    flexGrow: 1,
  },
  tableCell_Name: {
    width: "30%",
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    padding: 3,
    fontSize: "8px",
    flexGrow: 1,
  },
  tableCell_A: {
    width: "6%",
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    padding: 3,
    fontSize: "8px",
    flexGrow: 1,
  },
  tableCell_Kgs: {
    width: "7%",
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    padding: 3,
    fontSize: "8px",
    flexGrow: 1,
  },
  tableCell_Up: {
    width: "12%",
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    padding: 3,
    fontSize: "8px",
    flexGrow: 1,
  },
  tableCell_Amt: {
    width: "12%",
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    padding: 3,
    fontSize: "8px",
    flexGrow: 1,
  },
  tableCell_Tkgs: {
    width: "10%",
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    padding: 3,
    fontSize: "8px",
    flexGrow: 1,
  },
  tableCell_Tamt: {
    width: "12%",
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    padding: 3,
    fontSize: "8px",
    flexGrow: 1,
  },
  tableCell_Remarks: {
    width: "15%",
    borderRightWidth: 1,
    borderRightColor: "#000",
    borderBottomWidth: 1,
    borderBottomColor: "#000",
    padding: 3,
    fontSize: "8px",
    flexGrow: 1,
  },
});

export default function SalesHistoryPrint({ type, records, date,dateto, profile }) {
  const [totalQty, setTotalQty] = useState(0);
  const [totalTransactions, setTotalTransactions] = useState(0);

  const getTotalQty = () => {
    const sum = records
      .map((record) => parseFloat(record.salesQty))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    setTotalQty(sum.toFixed(2));
  };

  const getType = () => {
    switch (type) {
      case "1":
        return "Cash";
      case "2":
        return "Dealer";
      case "3":
        return "RPR";
      default:
        return "Cash";
    }
  };

  useEffect(() => {
    if (records.length !== 0) {
      setTotalTransactions(records.length);
      getTotalQty();
    }
  }, [records]);

  return (
    <Document>
      <Page size={"LETTER"} style={styles.body} orientation="landscape">
        <Text style={styles.header} fixed>
          K-GAZ {getType()}
        </Text>
        <View style={styles.container}>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Prepared By:{" "}
              <Text
                style={styles.fieldValue}
              >{`${profile.firstName} ${profile.lastName}`}</Text>
            </Text>
          </View>
        </View>
        <View style={styles.container}>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Date: <Text style={styles.fieldValue}>{date} - {dateto}</Text>
            </Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Print timestamp:{" "}
              <Text style={styles.fieldValue}>
                {format(new Date(), "yyyy-MM-dd HH:mm:ss", {
                  timeZone: "Asia/Manila",
                })}
              </Text>
            </Text>
          </View>
        </View>
        <View style={{ ...styles.container, marginBottom: 20 }}>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Total QTY: <Text style={styles.fieldValue}>{totalQty}</Text>
            </Text>
          </View>
          <View style={styles.column}>
            <Text style={styles.fieldName}>
              Total Transactions:{" "}
              <Text style={styles.fieldValue}>{totalTransactions}</Text>
            </Text>
          </View>
        </View>

        <View style={{ marginBottom: 15 }}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_No}>
                <Text>No.</Text>
              </View>
              <View style={styles.tableCell_Ts}>
                <Text>T.S.</Text>
              </View>
              <View style={styles.tableCell_Or}>
                <Text>O.R.</Text>
              </View>
              <View style={styles.tableCell_Name}>
                <Text>Customer's Name</Text>
              </View>
              {/* for X */}
              <View style={styles.tableCell_A}>
                <Text>X</Text>
              </View>
              <View style={styles.tableCell_Kgs}>
                <Text>KGS.</Text>
              </View>
              <View style={styles.tableCell_Up}>
                <Text>UP.</Text>
              </View>
              <View style={styles.tableCell_Amt}>
                <Text>Amount</Text>
              </View>
              {/* end of for X */}
              {/* for A */}
              <View style={styles.tableCell_A}>
                <Text>A</Text>
              </View>
              <View style={styles.tableCell_Kgs}>
                <Text>KGS.</Text>
              </View>
              <View style={styles.tableCell_Up}>
                <Text>UP.</Text>
              </View>
              <View style={styles.tableCell_Amt}>
                <Text>Amount</Text>
              </View>
              {/* end of For A */}

              {/* for B */}
              <View style={styles.tableCell_A}>
                <Text>B</Text>
              </View>
              <View style={styles.tableCell_Kgs}>
                <Text>KGS.</Text>
              </View>
              <View style={styles.tableCell_Up}>
                <Text>UP.</Text>
              </View>
              <View style={styles.tableCell_Amt}>
                <Text>Amount</Text>
              </View>
              {/* end of for B */}

              {/* for C */}
              <View style={styles.tableCell_A}>
                <Text>C</Text>
              </View>
              <View style={styles.tableCell_Kgs}>
                <Text>KGS.</Text>
              </View>
              <View style={styles.tableCell_Up}>
                <Text>UP.</Text>
              </View>
              <View style={styles.tableCell_Amt}>
                <Text>Amount</Text>
              </View>
              {/* end of for C */}

              {/* for Y */}
              <View style={styles.tableCell_A}>
                <Text>Y</Text>
              </View>
              <View style={styles.tableCell_Kgs}>
                <Text>KGS.</Text>
              </View>
              <View style={styles.tableCell_Up}>
                <Text>UP.</Text>
              </View>
              <View style={styles.tableCell_Amt}>
                <Text>Amount</Text>
              </View>
              {/* end of for Y */}

              <View style={styles.tableCell_Tkgs}>
                <Text>T.KGS.</Text>
              </View>
              <View style={styles.tableCell_Tamt}>
                <Text>Total Amount</Text>
              </View>
              <View style={styles.tableCell_Remarks}>
                <Text>Remarks</Text>
              </View>
            </View>
            {records.map((record, index) => (
              <View style={styles.tableRow}>
                <View style={styles.tableCell_No}>
                  <Text>{record.seqno}</Text>
                </View>
                <View style={styles.tableCell_Ts}>
                  <Text>{record.transhdr_id}</Text>
                </View>
                <View style={styles.tableCell_Or}>
                  <Text>{record.orderNumber}</Text>
                </View>
                <View style={styles.tableCell_Name}>
                  <Text>{record.customer_name}</Text>
                </View>

                <View style={styles.tableCell_A}>
                  <Text></Text>
                </View>
                <View style={styles.tableCell_Kgs}>
                  <Text></Text>
                </View>
                <View style={styles.tableCell_Up}>
                  <Text></Text>
                </View>
                <View style={styles.tableCell_Amt}>
                  <Text></Text>
                </View>

                <View style={styles.tableCell_A}>
                  {/* {record.orderList.map((order, orderIndex) => (
                    <Text key={orderIndex}>{order.salesQty}</Text> // Display salesQty directly
                  ))} */}
                  <Text>{record.count_a}</Text>
                </View>
                <View style={styles.tableCell_Kgs}>
                  <Text>{record.qty_a}</Text>
                </View>
                <View style={styles.tableCell_Up}>
                  <Text>{record.amount_a}</Text>
                </View>
                <View style={styles.tableCell_Amt}>
                  <Text>{record.amount_a}</Text>
                </View>


                <View style={styles.tableCell_A}>
                  <Text>{record.count_b}</Text>
                </View>
                <View style={styles.tableCell_Kgs}>
                  <Text>{record.qty_b}</Text>
                </View>
                <View style={styles.tableCell_Up}>
                  <Text>{record.amount_b}</Text>
                </View>
                <View style={styles.tableCell_Amt}>
                <Text>{record.amount_b}</Text>
                </View>

                <View style={styles.tableCell_A}>
                  <Text>{record.count_c}</Text>
                </View>
                <View style={styles.tableCell_Kgs}>
                  <Text>{record.qty_c}</Text>
                </View>
                <View style={styles.tableCell_Up}>
                  <Text>{record.amount_c}</Text>
                </View>
                <View style={styles.tableCell_Amt}>
                  <Text>{record.amount_c}</Text>
                </View>

{/* for y */}
                <View style={styles.tableCell_A}>
                  <Text></Text>
                </View>
                <View style={styles.tableCell_Kgs}>
                  <Text></Text>
                </View>
                <View style={styles.tableCell_Up}>
                  <Text></Text>
                </View>
                <View style={styles.tableCell_Amt}>
                  <Text></Text>
                </View>
                {/* end of y */}



                <View style={styles.tableCell_Tkgs}>
                  <Text>{record.total_qty}</Text>
                </View>
                <View style={styles.tableCell_Tamt}>
                  <Text>{record.total_amount}</Text>
                </View>
                <View style={styles.tableCell_Remarks}>
                  <Text></Text>
                </View>
              </View>
            ))}
          </View>
        </View>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
}
